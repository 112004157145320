<template>
  <!-- eslint-disable max-len -->
  <div class="uk-container">
    <form @submit.prevent="submit2" class="uk-form-stacked">
      <div class="uk-margin">
        <label class="uk-form-label uk-text-left" for="form-stacked-name"
          ><span class="uk-text-danger" v-if="!$v.usuario.name.required"
            >*</span
          >
          Nombre</label
        >
        <div class="uk-form-controls">
          <input
            class="uk-input uk-form-width-large"
            id="form-stacked-name"
            type="text"
            placeholder="Ej. Rusia"
            v-model.trim="$v.usuario.name.$model"
            :class="{ 'uk-form-danger': $v.usuario.name.$error }"
          />
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label uk-text-left" for="form-stacked-lastName"
          ><span class="uk-text-danger" v-if="!$v.usuario.lastname.required"
            >*</span
          >
          Apellidos</label
        >
        <div class="uk-form-controls">
          <input
            class="uk-input uk-form-width-large"
            id="form-stacked-lastName"
            type="text"
            placeholder="Ej. Morales Peinado"
            v-model.trim="$v.usuario.lastname.$model"
            :class="{ 'uk-form-danger': $v.usuario.lastname.$error }"
          />
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label uk-text-left" for="form-stacked-email">
          <span class="uk-text-danger" v-if="!$v.usuario.email.required"
            >*</span
          >
          Email</label
        >
        <div class="uk-form-controls">
          <input
            class="uk-input uk-form-width-large"
            id="form-stacked-email"
            type="email"
            placeholder="Ej. micorreo@me.com"
            v-model.trim="$v.usuario.email.$model"
            :class="{ 'uk-form-danger': $v.usuario.email.$error }"
          />
          <div>
            <span class="uk-text-danger" v-if="!$v.usuario.email.email"
              >El email ingresado es incorrecto</span
            >
          </div>
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label uk-text-left" for="form-stacked-password"
          ><span class="uk-text-danger" v-if="!$v.usuario.password.required"
            >*</span
          >
          Contraseña</label
        >
        <div class="uk-form-controls">
          <input
            class="uk-input uk-form-width-large"
            id="form-stacked-password"
            type="password"
            placeholder="Ej. 123456 (Mínimo 6 caracteres)"
            v-model.trim="$v.usuario.password.$model"
            :class="{ 'uk-form-danger': $v.usuario.password.$error }"
          />
          <div>
            <span
              class="uk-text-danger"
              v-if="!this.$v.usuario.password.minLength"
              >Mínimo 6 caracteres</span
            >
          </div>
        </div>
      </div>

      <div class="uk-margin">
        <label class="uk-form-label" for="form-stacked-text">
          <span class="uk-text-danger" v-if="!validatePhone.isValid"> * </span>
          Teléfono
        </label>
        <div class="uk-form-controls">
          <VuePhoneNumberInput
            default-country-code="MX"
            :no-country-selector="validatePhone.countryCode === null"
            @update="dataPhone"
            v-model="$v.usuario.phone.$model.number"
            :error="!validatePhone.isValid"
            :translations="{
              countrySelectorLabel: 'CÓDIGO DE PAÍS',
              countrySelectorError: 'ELIGE UN PAÍS',
              phoneNumberLabel: 'NÚMERO DE TELÉFONO',
              example: 'EJEMPLO:',
            }"
          />
        </div>
      </div>
      <div class="uk-margin uk-text-center">
        <small class="uk-text-danger" v-if="$v.$invalid"
          >Los campos con * son obligatorios</small
        >
      </div>
      <div
        class="uk-alert-danger uk-text-center"
        uk-alert
        v-if="errors.tipo !== null"
      >
        <a class="uk-alert-close" uk-close></a>
        <span>
          {{ errors.mensaje }}
        </span>
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-align-center app-bg uk-margin-medium-top uk-margin-small-bottom"
          :disabled="$v.$invalid || !validatePhone.isValid"
        >
          Registrarse
        </button>
      </div>
      <hr />
    </form>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  required,
  email,
  // eslint-disable-next-line no-unused-vars
  sameAs,
  minLength,
  // eslint-disable-next-line no-unused-vars
  between,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      validatePhone: {},
      usuario: {
        name: "",
        lastname: "",
        email: "",
        password: "",
        phone: {
          number: null,
        },
      },
    };
  },
  computed: {
    ...mapState(["errors"]),
  },
  validations: {
    usuario: {
      name: {
        required,
        minLength: 2,
      },
      lastname: {
        required,
        minLength: 2,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      phone: {
        required,
      },
    },
  },
  methods: {
    submit2() {
      console.log("submit!");
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.submitStatus = 'ERROR';
        console.log("Se generó un error");
      } else {
        console.log("Se enviaron los datos Correctamente!");
        // eslint-disable-next-line no-unused-expressions
        (this.usuario.phone = {
          number: this.usuario.phone.number,
          countryCode: this.validatePhone.countryCode,
          formattedNumber: this.validatePhone.formattedNumber,
          countryCallingCode: this.validatePhone.countryCallingCode,
          nationalNumber: this.validatePhone.nationalNumber,
          // eslint-disable-next-line no-sequences
        }),
          console.log(this.usuario);
        this.$emit("procesar", this.usuario);
      }
    },

    dataPhone({
      countryCode,
      isValid,
      phoneNumber,
      countryCallingCode,
      formattedNumber,
      formatInternational,
      nationalNumber,
    }) {
      // console.log({
      // eslint-disable-next-line max-len
      //   countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber, formatInternational,
      // });

      this.validatePhone = {
        countryCode,
        isValid,
        phoneNumber,
        countryCallingCode,
        formattedNumber,
        formatInternational,
        nationalNumber,
      };
    },
  },
  created() {},
};
</script>

<style></style>
